<template>
    <div class="FacilityList">
        <main-header menuActive="facility" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>시설관리</h2>
                </div>
                <div class="row searchBtn">
                    <div class="link_tab pull-left">
                        <div class="InsulatorTab">
                            <ul v-if="sort_data">
                                <li v-for="(data, index) in sort_data" :key="index" :class="{'on':(data.code==sort_key)}">
                                    <a @click="sort_key = data.code, searchFunc()">
                                        <span>{{data.name}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="pull-right">
                        <a v-if="['G1106'].includes(userDataAdminPermission)"
                           class="btn10"
                           @click="searchDetailFunc()"
                        >
                            시설등록
                        </a>
                        <!--                        <a class="btn8">엑셀다운로드</a>-->
                    </div>
                </div>
                <div class="row">
                    <div class="form_box">
                        <div class="row no-pdd-top">
                            <div class="searchBox">
                                <!-- 검색키워드 -->
                                <searchConditionBox
                                        searchTitle="키워드"
                                        :searchConditionCombo.sync="searchConditionCombo"
                                        :searchKeywordText.sync="sch_keyword"
                                />
                            </div>
                        </div>
                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchFunc">조회</a>
                            <a class="btn8" @click="reset">초기화</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-striped table-hover">
                            <colgroup>
                                <col width="5%" />
                                <col width="10%" />
                                <!--                                <col width="10%" />-->
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col />
                            </colgroup>
                            <thead>
                            <tr>
                                <th>NO</th>
                                <th>구분</th>
                                <!--                                <th>지역</th>-->
                                <th>시설명</th>
                                <th>총 병상</th>
                                <th>사용중병상</th>
                                <th>입실불가능병상</th>
                                <th>입실가능병상</th>
                                <th>입실대기</th>
                                <th>관리</th>
                            </tr>
                            </thead>
                            <tbody v-if="dataList.length > 0">
                            <tr v-for="(data, index) in dataList" :key="index">
                                <td>{{parseInt(totalCount) - ((parseInt(page)-1)*20 + (parseInt(index)) ) }}</td>
                                <td>{{ data.typeCode }}</td>
                                <!--                                <td>{{ data.f_region }}</td>-->
                                <td>{{ data.fasilityName }}</td>
                                <td>{{ data.totalRoom | comma }}</td>
                                <td>{{ data.useRoom | comma }}</td>
                                <td>{{ data.imposibleRoom | comma }}</td>
                                <td>{{ data.posibleRoom | comma }}</td>
                                <td>{{ data.waitRoom | comma }}</td>
                                <td>
                                    <template v-if="['G1106'].includes(userDataAdminPermission)">
                                        <button type="button"
                                                class="btn btn-sm btn-info mrg-right-5"
                                                @click.stop="popupFacilityStatusOpenFunc(data)"
                                        >
                                            현황등록
                                        </button>
                                        <button type="button"
                                                class="btn btn-sm btn-primary mrg-right-5"
                                                @click.stop="searchDetailFunc(data)"
                                        >
                                            수정
                                        </button>
                                        <button type="button"
                                                class="btn btn-sm btn-danger mrg-right-5"
                                                @click.stop="deleteConfirmFunc(data.seq)"
                                        >
                                            삭제
                                        </button>
                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td colspan="9">검색결과가 존재 하지 않습니다.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <paginate v-model="page" :page-count="totalPage" :prev-text="'Prev'" :next-text="'Next'" :containerClass="'pagination'" :click-handler="changePage"></paginate>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />

        <!-- 현황등록 팝업 -->
        <popup-facility-status ref="popupFacilityStatus" />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';
import searchConditionBox from '@/components/SearchConditionBox';
import Paginate from 'vuejs-paginate'
import popupFacilityStatus from '@/components/PopupFacilityStatus.vue';

export default {
    name: 'FacilityList',
    data() {
        return {
            sort_key : '',        // Tab 정보
            sort_data : [],
            searchConditionCombo : 'name',
            sch_keyword:'',

            page:1,
            totalPage:0,
            totalCount:0,
            dataList:[],
        }
    },
    components: { mainHeader, mainFooter, searchConditionBox, Paginate, popupFacilityStatus },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
        this.checkLogin();
        this.init();
    },
    // $el 생성후
    mounted() {
        if (this.$route.params.info){
            this.sch_keyword = this.$route.params.sch_keyword;
            this.sort_key = this.$route.params.sort_key;
            this.page = this.$route.params.page;
            this.searchListFunc(this.page)
        }else{

            if (this.$route.params && this.$route.params.data){
                this.sort_key = this.$route.params.data.sort_key;
                this.searchFunc();
            }else{
                this.reset();
            }

        }

    },
    methods: {
        init(){
            this.ajaxGet('nocCommon/code?params=', null, 'initResult');

            /*
            let formData = new FormData();
            formData.append('api_name', 'patientCreateCode');
            this.ajaxPost('', formData,'initResult');
            */
        },
        initResult(response){
            let result = response;

            // 시설별, 시설별 팝업
            let vm = this;
            result.fasilityCodeList.forEach(function(obj){
                vm.sort_data.push(obj)

            });
            this.sort_data.unshift({ code:'', name:'전체' });
            console.log(this.sort_data);
        },
        // 초기화
        reset(){
            this.sch_keyword = '';
            this.sort_key = '';
            this.page = 1;

            this.searchFunc();
        },
        searchFunc(){
            this.searchListFunc(1);
            this.page = 1;
        },
        // 리스트 검색
        searchListFunc(pageIndex){
            let params = `{
                "pagination": {
                    "curPage": "${pageIndex}",
                    "pageScale": 20
                },
                "search": {
                    "tapkey": "${this.sort_key}",
                    "keyword": "${this.searchConditionCombo}",
                    "keyword_txt": "${this.sch_keyword}"
                }
            }`;
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocFacility/list?params=' + queryString, null, 'searchListFuncResult');

            /*
            let formData = new FormData();
            formData.append('api_name', 'fasilityList');                    // API 이름
            formData.append('sch_keyword', this.sch_keyword);	                    // 검색 키워드
            formData.append('sort_key', this.sort_key);	                        // Tab
            formData.append('page_num', pageIndex);	                            // 현재 페이지 번호
            this.page = pageIndex;

            this.ajaxPost('', formData,'searchListFuncResult');
            */
        },
        searchListFuncResult(response) {
            let result = response;
            this.dataList = result.list;

            if (result.count == 0){
                this.totalPage = 0;
                this.totalCount = 0;
            } else {
                this.totalPage = parseInt(result.pagination.maxPage);
                this.totalCount = parseInt(result.count);
            }
        },
        // 페이지 변경
        changePage(){
            this.searchListFunc(this.page)
        },
        // 상세페이지
        searchDetailFunc(obj){
            let data={};
            data.sch_keyword = this.sch_keyword;
            data.sort_key = this.sort_key;
            data.info = (obj) ? obj : {};
            data.info.f_idx = (obj) ? obj.seq : "";
            data.page = this.page;

            console.log(data);

            this.$router.push({ name: 'AdminFacilityDetail', params: {data} })
        },
        // 삭제 컨펀창
        deleteConfirmFunc(f_idx){
            this.showConfirm("", "삭제 하시겠습니까?", "deleteConfirm", f_idx);
        },
        // 삭제 처리
        deleteConfirm(f_idx){
            console.log(f_idx);
            let data = {
              "fIdx": f_idx,
              "adminId": this.userDataAdminId,
            }

            this.ajaxPost('nocFacility/delete', data,'deleteConfirmResult');
            /*
            let formData = new FormData();
            formData.append('api_name', 'fasilityStateChange');
            formData.append('f_idx', f_idx);
            formData.append('f_admin_id', this.userDataAdminId);
            formData.append('f_change_code', 'DEL');

            this.ajaxPost('', formData,'deleteConfirmResult');
            */
        },
        // 삭제 처리 결과
        deleteConfirmResult() {
            this.showAlert('', '삭제처리 되었습니다.', "searchListFunc", 1);
        },
        popupFacilityStatusOpenFunc(obj){
            obj.f_idx = obj.seq;
            this.$refs.popupFacilityStatus.init(true, obj, this.page)
        },
    }
}
</script>

<style scoped>
</style>
