<template>
    <div class="SearchConditionCombo inline-block">
        <label class="searchTitle" v-if="searchTitle">{{ searchTitle }}</label>
        <select class="select_ansimi width150" :value="searchConditionCombo" @change="$emit('update:searchConditionCombo', $event.target.value)">
            <option v-for="(data, index) in tempSearchCondition" :key="index" :value="data.code">{{data.name}}</option>
        </select>
        <input type="text" class="input type2 width300 mrg-left-10" :value="searchKeywordText" @input="$emit('update:searchKeywordText', $event.target.value)" />
    </div>
</template>

<script>
    export default {
        name: 'SearchConditionCombo',
        data() {
            return {
                tempSearchCondition:[]
            }
        },
        props: ['searchTitle','searchConditionCombo','searchKeywordText', 'searchComboCategory'],
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
            if (this.searchComboCategory == 'confirmedList') {
                this.tempSearchCondition = this.searchConditionConfirmedList;
            }else{
                this.tempSearchCondition = this.searchCondition;
            }

            this.tempSearchCondition.unshift({code:'0',name:'전체'});
        },
        // $el 생성후
        mounted() {
            this.$nextTick(function() {
            });
        },
        methods: {
        }
    }
</script>
<style scoped>
</style>
