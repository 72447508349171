<template>
    <div id="footer" class="MainFooter">
        <p class="copy">© 서울시 코로나 확진자 관리시스템 All rights reserved.</p>
    </div>
</template>

<script>
    export default {
        name: 'MainFooter',
        data() {
            return {
            }
        },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {
        },
        methods: {
        }
    }
</script>
<style scoped>
</style>
