<template>
    <div class="PopupFacilityStatus" v-show="isVisible==true">
        <section class="prdImgPopup_wrap">
            <div class="row">
                <div>
                    <div class="subHead">
                        <h3># 현황등록 </h3>
                    </div>
                    <table class="table table-profile">
                        <colgroup>
                            <col width="20%">
                            <col width="80%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">구분</th>
                            <td class="text-left">{{ data.f_type }}</td>
                        </tr>
<!--                        <tr>-->
<!--                            <th class="field">지역</th>-->
<!--                            <td class="text-left">{{ data.f_region }}</td>-->
<!--                        </tr>-->
                        <tr>
                            <th class="field">시설명</th>
                            <td class="text-left">{{ data.f_fasility_name }}</td>
                        </tr>
                        <tr>
                            <th class="field">총병상</th>
                            <td class="text-left"><input type="text" class='input type2 width-100' v-model="f_total_room"  onKeyup="this.value=this.value.replace(/[^0-9]/g,'');" /></td>
                        </tr>
                        <tr>
                            <th class="field">사용중병상</th>
                            <td class="text-left">{{ f_use_room }}</td>
                        </tr>
                        <tr>
                            <th class="field">입실불가능병상</th>
                            <td class="text-left"><input type="text" class='input type2 width-100' v-model="f_imposible_room" onKeyup="this.value=this.value.replace(/[^0-9]/g,'');" /></td>
                        </tr>
                        <tr>
                            <th class="field">입실가능병상</th>
                            <td class="text-left">{{ f_unused_room }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="isVisible=false">취소</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">저장</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="dimmed" @click="isVisible=false"></div>
    </div>
</template>

<script>
    export default {
        name: 'PopupFacilityStatus',
        data() {
            return {
                isVisible:false,
                data:[],
                f_total_room : 0,
                f_use_room : 0,
                f_unused_room : 0,
                f_imposible_room : 0,
                page:'',                            // 페이지
            }
        },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {

        },
        methods: {
            init(isVisible, obj, page){
                console.log("팝업내부");
                console.log(obj);
                let params = `{"fIdx":${obj.f_idx}}`;
                let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
                this.ajaxGet('nocFacility/details?params=' + queryString, null, 'initResult');

                this.isVisible = isVisible;

                /*
                this.data = obj;
                this.f_total_room = obj.f_total_room;
                this.f_use_room = obj.f_use_room;
                this.f_unused_room = obj.f_unused_room;
                this.f_imposible_room = obj.f_imposible_room;
                */
                this.page = page;

            },
            initResult(response){
                let result = response.list;

                this.data.f_idx = result.fidx;
                this.data.f_type = result.ftypeCode;
                // this.data.f_region = result.fidx;
                this.data.f_fasility_name = result.ffasilityName;
                this.f_total_room = result.ftotalRoom;
                this.f_use_room = result.fuseRoom;
                this.f_unused_room = result.funusedRoom;
                this.f_imposible_room = result.fimposibleRoom;
            },
            sendFunc(){
                // if (!this.f_use_room){
                //     this.showAlert('', '사용중병상을 입력해 주세요.');
                //     return false;
                // }

                if (!this.f_imposible_room){
                    this.showAlert('', '사용불가능병상을 입력해 주세요.');
                    return false;
                }

                // if (!this.f_unused_room){
                //     this.showAlert('', '사용가능병상을 입력해 주세요.');
                //     return false;
                // }

                let data = {
                    "fIdx": this.data.f_idx,
                    "fTotalRoom" : this.f_total_room,
                    "fImposibleRoom": this.f_imposible_room,
                    "adminId": this.userDataAdminId
                }
                this.ajaxPost('nocFacility/updates', data,'sendFuncResult');

                /*
                let formData = new FormData();
                formData.append('api_name', 'fasilityRoomChange');
                formData.append('f_idx', this.data.f_idx);
                formData.append('f_total_room', this.f_total_room);
                // formData.append('f_use_room', this.f_use_room);
                formData.append('f_imposible_room', this.f_imposible_room);
                // formData.append('f_unused_room', this.f_unused_room);
                formData.append('f_admin_id', this.userDataAdminId);

                this.ajaxPost('', formData,'sendFuncResult');
                */
            },
            sendFuncResult(response) {
                this.showAlert('', '저장 되었습니다.',"close");
            },
            close(){
                this.isVisible=false;
                this.$parent.searchListFunc(this.page);
            }
        }
    }
</script>
<style scoped>
    .prdImgPopup_wrap {position: fixed;left:50%;top:50%;transform: translate(-50%, -50%); z-index: 9999;zoom:1;width: 500px;
        background: #fff;
        padding: 0 20px 20px;}
    .dimmed{background-color: #000;opacity: 0.5;position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 999;}
</style>
